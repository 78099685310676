@font-face {
  font-family: Geometria;
  src: url(../fonts/Geometria-LightItalic.eot);
  src: url(../fonts/Geometria-LightItalic.eot?#iefix) format("embedded-opentype"),url(../fonts/Geometria-LightItalic.woff2) format("woff2"),url(../fonts/Geometria-LightItalic.woff) format("woff"),url(../fonts/Geometria-LightItalic.ttf) format("truetype"),url(../fonts/Geometria-LightItalic.svg#Geometria-LightItalic) format("svg");
  font-weight: 300;
  font-style: italic
}
@font-face {
  font-family: Geometria;
  src: url(../fonts/Geometria-ExtraLightItalic.eot);
  src: url(../fonts/Geometria-ExtraLightItalic.eot?#iefix) format("embedded-opentype"),url(../fonts/Geometria-ExtraLightItalic.woff2) format("woff2"),url(../fonts/Geometria-ExtraLightItalic.woff) format("woff"),url(../fonts/Geometria-ExtraLightItalic.ttf) format("truetype"),url(../fonts/Geometria-ExtraLightItalic.svg#Geometria-ExtraLightItalic) format("svg");
  font-weight: 200;
  font-style: italic
}
@font-face {
  font-family: Geometria;
  src: url(../fonts/Geometria-Light.eot);
  src: url(../fonts/Geometria-Light.eot?#iefix) format("embedded-opentype"),url(../fonts/Geometria-Light.woff2) format("woff2"),url(../fonts/Geometria-Light.woff) format("woff"),url(../fonts/Geometria-Light.ttf) format("truetype"),url(../fonts/Geometria-Light.svg#Geometria-Light) format("svg");
  font-weight: 300;
  font-style: normal
}
@font-face {
  font-family: Geometria;
  src: url(../fonts/Geometria-HeavyItalic.eot);
  src: url(../fonts/Geometria-HeavyItalic.eot?#iefix) format("embedded-opentype"),url(../fonts/Geometria-HeavyItalic.woff2) format("woff2"),url(../fonts/Geometria-HeavyItalic.woff) format("woff"),url(../fonts/Geometria-HeavyItalic.ttf) format("truetype"),url(../fonts/Geometria-HeavyItalic.svg#Geometria-HeavyItalic) format("svg");
  font-weight: 900;
  font-style: italic
}
@font-face {
  font-family: Geometria;
  src: url(../fonts/Geometria-ExtraLight.eot);
  src: url(../fonts/Geometria-ExtraLight.eot?#iefix) format("embedded-opentype"),url(../fonts/Geometria-ExtraLight.woff2) format("woff2"),url(../fonts/Geometria-ExtraLight.woff) format("woff"),url(../fonts/Geometria-ExtraLight.ttf) format("truetype"),url(../fonts/Geometria-ExtraLight.svg#Geometria-ExtraLight) format("svg");
  font-weight: 200;
  font-style: normal
}
@font-face {
  font-family: Geometria;
  src: url(../fonts/Geometria-BoldItalic.eot);
  src: url(../fonts/Geometria-BoldItalic.eot?#iefix) format("embedded-opentype"),url(../fonts/Geometria-BoldItalic.woff2) format("woff2"),url(../fonts/Geometria-BoldItalic.woff) format("woff"),url(../fonts/Geometria-BoldItalic.ttf) format("truetype"),url(../fonts/Geometria-BoldItalic.svg#Geometria-BoldItalic) format("svg");
  font-weight: 700;
  font-style: italic
}
@font-face {
  font-family: Geometria;
  src: url(../fonts/Geometria-ExtraBold.eot);
  src: url(../fonts/Geometria-ExtraBold.eot?#iefix) format("embedded-opentype"),url(../fonts/Geometria-ExtraBold.woff2) format("woff2"),url(../fonts/Geometria-ExtraBold.woff) format("woff"),url(../fonts/Geometria-ExtraBold.ttf) format("truetype"),url(../fonts/Geometria-ExtraBold.svg#Geometria-ExtraBold) format("svg");
  font-weight: 800;
  font-style: normal
}
@font-face {
  font-family: Geometria;
  src: url(../fonts/Geometria-Italic.eot);
  src: url(../fonts/Geometria-Italic.eot?#iefix) format("embedded-opentype"),url(../fonts/Geometria-Italic.woff2) format("woff2"),url(../fonts/Geometria-Italic.woff) format("woff"),url(../fonts/Geometria-Italic.ttf) format("truetype"),url(../fonts/Geometria-Italic.svg#Geometria-Italic) format("svg");
  font-weight: 400;
  font-style: italic
}
@font-face {
  font-family: Geometria;
  src: url(../fonts/Geometria-Medium.eot);
  src: url(../fonts/Geometria-Medium.eot?#iefix) format("embedded-opentype"),url(../fonts/Geometria-Medium.woff2) format("woff2"),url(../fonts/Geometria-Medium.woff) format("woff"),url(../fonts/Geometria-Medium.ttf) format("truetype"),url(../fonts/Geometria-Medium.svg#Geometria-Medium) format("svg");
  font-weight: 500;
  font-style: normal
}
@font-face {
  font-family: Geometria;
  src: url(../fonts/Geometria-ExtraBoldItalic.eot);
  src: url(../fonts/Geometria-ExtraBoldItalic.eot?#iefix) format("embedded-opentype"),url(../fonts/Geometria-ExtraBoldItalic.woff2) format("woff2"),url(../fonts/Geometria-ExtraBoldItalic.woff) format("woff"),url(../fonts/Geometria-ExtraBoldItalic.ttf) format("truetype"),url(../fonts/Geometria-ExtraBoldItalic.svg#Geometria-ExtraBoldItalic) format("svg");
  font-weight: 800;
  font-style: italic
}
@font-face {
  font-family: Geometria;
  src: url(../fonts/Geometria-Thin.eot);
  src: url(../fonts/Geometria-Thin.eot?#iefix) format("embedded-opentype"),url(../fonts/Geometria-Thin.woff2) format("woff2"),url(../fonts/Geometria-Thin.woff) format("woff"),url(../fonts/Geometria-Thin.ttf) format("truetype"),url(../fonts/Geometria-Thin.svg#Geometria-Thin) format("svg");
  font-weight: 100;
  font-style: normal
}
@font-face {
  font-family: Geometria;
  src: url(../fonts/Geometria-MediumItalic.eot);
  src: url(../fonts/Geometria-MediumItalic.eot?#iefix) format("embedded-opentype"),url(../fonts/Geometria-MediumItalic.woff2) format("woff2"),url(../fonts/Geometria-MediumItalic.woff) format("woff"),url(../fonts/Geometria-MediumItalic.ttf) format("truetype"),url(../fonts/Geometria-MediumItalic.svg#Geometria-MediumItalic) format("svg");
  font-weight: 500;
  font-style: italic
}
@font-face {
  font-family: Geometria;
  src: url(../fonts/Geometria-ThinItalic.eot);
  src: url(../fonts/Geometria-ThinItalic.eot?#iefix) format("embedded-opentype"),url(../fonts/Geometria-ThinItalic.woff2) format("woff2"),url(../fonts/Geometria-ThinItalic.woff) format("woff"),url(../fonts/Geometria-ThinItalic.ttf) format("truetype"),url(../fonts/Geometria-ThinItalic.svg#Geometria-ThinItalic) format("svg");
  font-weight: 100;
  font-style: italic
}
@font-face {
  font-family: Geometria;
  src: url(../fonts/Geometria-Heavy.eot);
  src: url(../fonts/Geometria-Heavy.eot?#iefix) format("embedded-opentype"),url(../fonts/Geometria-Heavy.woff2) format("woff2"),url(../fonts/Geometria-Heavy.woff) format("woff"),url(../fonts/Geometria-Heavy.ttf) format("truetype"),url(../fonts/Geometria-Heavy.svg#Geometria-Heavy) format("svg");
  font-weight: 900;
  font-style: normal
}
@font-face {
  font-family: Geometria;
  src: url(../fonts/Geometria.eot);
  src: url(../fonts/Geometria.eot?#iefix) format("embedded-opentype"),url(../fonts/Geometria.woff2) format("woff2"),url(../fonts/Geometria.woff) format("woff"),url(../fonts/Geometria.ttf) format("truetype"),url(../fonts/Geometria.svg#Geometria) format("svg");
  font-weight: 400;
  font-style: normal
}
@font-face {
  font-family: Geometria;
  src: url(../fonts/Geometria-Bold.eot);
  src: url(../fonts/Geometria-Bold.eot?#iefix) format("embedded-opentype"),url(../fonts/Geometria-Bold.woff2) format("woff2"),url(../fonts/Geometria-Bold.woff) format("woff"),url(../fonts/Geometria-Bold.ttf) format("truetype"),url(../fonts/Geometria-Bold.svg#Geometria-Bold) format("svg");
  font-weight: 700;
  font-style: normal
}