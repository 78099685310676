// Fonts
@font-base: "Arial";

// Animation
@animation-time: 0.3s;
@double-animation: 0.6s;

// MediaQ - теперо подключаются в layout
// @desktop: ~"(max-width: 1439px)";
// @desktop-min: ~"(max-width: 1169px)";
// @tablet: ~"(max-width: 1023px)";
// @mobile: ~"(max-width: 767px)";

// Colors
// Цвета theme. Не трогать!!!
@cl-theme-global: @cl-font-main;
@cl-theme-link: @cl-font-main;
@cl-theme-link-hover: @cl-font-light;
@cl-theme-grey: @cl-gray;

// Цвета текста
@cl-font-main: @cl-black;
@cl-font-light: @cl-gray;

/* Добавлять по категориям cl-элемент
/* @cl-button-bg: @Var */

// Общий док
@background: @cl-white;

// разделитель между секция
@hr-main: @cl-gray;

// элементы формы (input, textarea, select, etc)
@form-back: @cl-white;
@form-border: @cl-gray;

// Checkbox
@checkbox-back: @cl-gray;
@checkbox-back-hover: @cl-gray;
@checkbox-back-checked: @cl-font-main;
@check-mark: @cl-white;

// Radiobutton
@radio-back: @cl-white;

// кнопки с бэком без бордера
@button-main-back: @cl-black;
@button-main-hover: @cl-gray;
@button-main-active: @cl-gray;

// кнопки прозрачные
@button-transparent-back: @cl-transparent;
@button-transparent-hover: @cl-gray;
@button-transparent-active: @cl-gray;





// ------
// ЦВЕТА 
// ------

// // Alpha colors
// // @cl-alpha: rgba(255, 255, 255, .2); // цвет + описание

// Основные цвета
@cl-transparent: transparent;
@cl-white: #ffffff;
@cl-black: #000000;
@cl-gray: #dddddd;
// Генерируемые цвета - ниже не пишем ничего сами )