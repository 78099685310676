*,
*:before,
*:after {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    background-color: @background;
}

body {
    font-size: 14px;
    font-family: @font-base;
    line-height: 21px;
    color: @cl-theme-global;
    vertical-align: top;
    -webkit-text-size-adjust: 100%;
    margin: 0;
    padding: 0;
}

// global

img {
    max-width: 100%;
    height: auto;
}

a {
    color: @cl-theme-link;
    text-decoration: none;
    outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
    color: @cl-theme-global;
    font-size: 22px;
    font-weight: normal;
}


hr {
    background: none;
    border: none;
    border-bottom: 1px solid @cl-theme-grey;
    display: block;
    position: relative;
    height: 1px;
    margin: 15px 0;
    line-height: 0;
    font-size: 0;
}

ul {
    margin: 0px;
}

ul li {
    list-style: disc inside;
    margin-bottom: 0px;
}

ol {
    margin-bottom: 0px;
}

.table {
    border: 1px solid @cl-theme-grey;
    margin: 0;
}
th,
td {
    background: none;
    border: 1px solid @cl-theme-grey;
    padding: 1px;
    text-align: left;
}

q,
blockquote {
    border: none;
    font-size: 14px;
    line-height: 24px;
    margin: 0px auto;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
}

fieldset {
    margin-right: 0;
    margin-left: 0;
}

// clear
.clearfix {
    position: relative;
    zoom: 1;
}

.clear {
    clear: both;
    height: 1px;
    line-height: 1px;
    overflow: hidden;
    margin-top: -1px;
}


// form
form {
    display: block;
    clear: both;
    position: relative;
    zoom: 1;
}

label,
.label {
    display: block;
    font-weight: normal;//Сброс значения bold, идущего из битрикса
    position: relative;
    clear: both;
    width: 100%;
    margin: 0;
    zoom: 1;

}

input,
textarea,
select {
    background: #fff;
    border: 1px solid @cl-theme-grey;
    box-shadow: none;
    display: block;
    position: relative;
    width: 100%;
    color: @cl-theme-global;
    vertical-align: top;
    outline: none;
    zoom: 1;
    font-family: @font-base;
}

input[type="file"] {
    display: block;
    position: relative;
    margin: 0;
}

textarea {
    height: auto;
    resize: vertical;
}

select {
    padding: 0;
}

// placeholder 
::-webkit-input-placeholder {
    color: @cl-theme-global;
    opacity: 1;
}

:-moz-placeholder {
    color: @cl-theme-global;
    opacity: 1;
}

::-moz-placeholder {
    color: @cl-theme-global;
    opacity: 1;
}

:-ms-input-placeholder {
    color: @cl-theme-global;
    opacity: 1;
}
