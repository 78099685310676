@font-face {
	font-family: "icons";
	src: url('../fonts/icons.eot');
	src: url('../fonts/icons.eot?#iefix') format('eot'),
		url('../fonts/icons.woff2') format('woff2'),
		url('../fonts/icons.woff') format('woff'),
		url('../fonts/icons.ttf') format('truetype'),
		url('../fonts/icons.svg#icons') format('svg');
}

.icon-base-pseudo {
	font-family: "icons";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	// speak: none; // only necessary if not using the private unicode range (firstGlyph option)
	text-decoration: none;
	text-transform: none;
}

.icon-char(@filename) {
	@arrow-left: "\E001";
	@arrow-right: "\E002";
	@bus: "\E003";
	@car: "\E004";
	@close: "\E005";
	@download: "\E006";
	@facebook: "\E007";
	@forum: "\E008";
	@home: "\E009";
	@inst: "\E00A";
	@list: "\E00B";
	@logo-chef: "\E00C";
	@logo: "\E00D";
	@mark: "\E00E";
	@menu: "\E00F";
	@platform-1: "\E010";
	@platform-10: "\E011";
	@platform-11: "\E012";
	@platform-12: "\E013";
	@platform-13: "\E014";
	@platform-14: "\E015";
	@platform-15: "\E016";
	@platform-16: "\E017";
	@platform-2: "\E018";
	@platform-3: "\E019";
	@platform-4: "\E01A";
	@platform-5: "\E01B";
	@platform-6: "\E01C";
	@platform-7: "\E01D";
	@platform-8: "\E01E";
	@platform-9: "\E01F";
	@profi: "\E020";
	@telegram: "\E021";
	@tickets: "\E022";
	@vk: "\E023";
	@whatsup: "\E024";
	
	content: @@filename;
}

.icon(@filename, @insert: before) {
	@pseudo-selector: ~":@{insert}";

	&@{pseudo-selector} {
		&:extend(.icon-base-pseudo);
		.icon-char(@filename);
	}
}

.icon-arrow-left {
	.icon(arrow-left);
}
.icon-arrow-right {
	.icon(arrow-right);
}
.icon-bus {
	.icon(bus);
}
.icon-car {
	.icon(car);
}
.icon-close {
	.icon(close);
}
.icon-download {
	.icon(download);
}
.icon-facebook {
	.icon(facebook);
}
.icon-forum {
	.icon(forum);
}
.icon-home {
	.icon(home);
}
.icon-inst {
	.icon(inst);
}
.icon-list {
	.icon(list);
}
.icon-logo-chef {
	.icon(logo-chef);
}
.icon-logo {
	.icon(logo);
}
.icon-mark {
	.icon(mark);
}
.icon-menu {
	.icon(menu);
}
.icon-platform-1 {
	.icon(platform-1);
}
.icon-platform-10 {
	.icon(platform-10);
}
.icon-platform-11 {
	.icon(platform-11);
}
.icon-platform-12 {
	.icon(platform-12);
}
.icon-platform-13 {
	.icon(platform-13);
}
.icon-platform-14 {
	.icon(platform-14);
}
.icon-platform-15 {
	.icon(platform-15);
}
.icon-platform-16 {
	.icon(platform-16);
}
.icon-platform-2 {
	.icon(platform-2);
}
.icon-platform-3 {
	.icon(platform-3);
}
.icon-platform-4 {
	.icon(platform-4);
}
.icon-platform-5 {
	.icon(platform-5);
}
.icon-platform-6 {
	.icon(platform-6);
}
.icon-platform-7 {
	.icon(platform-7);
}
.icon-platform-8 {
	.icon(platform-8);
}
.icon-platform-9 {
	.icon(platform-9);
}
.icon-profi {
	.icon(profi);
}
.icon-telegram {
	.icon(telegram);
}
.icon-tickets {
	.icon(tickets);
}
.icon-vk {
	.icon(vk);
}
.icon-whatsup {
	.icon(whatsup);
}
